<template>
  <div v-if="currency" class="mt-5">
    <div v-if="!isMobile" class="section is-flex is-align-items-center py-2">
      <crypto-logo
        :ticker="currency.ticker"
        :name="currency.name"
        :url="currency.logoURL"
      >
      </crypto-logo>
      <span class="has-text-weight-bold mr-4">
        {{ currency.name }}
      </span>
      <span>{{ currency.ticker }}</span>
      <span v-if="hasValidTags(currency.tags)">
        <b-tag
          class="ml-2"
          v-for="(tag, index) in currency.tags"
          :key="index"
          type="is-success is-light"
          >{{ tag }}</b-tag
        >
      </span>
    </div>
    <div class="section py-2 mb-5" :class="{ 'px-2': isMobile }">
      <div class="columns is-desktop">
        <div v-if="!isMobile" class="column is-8-desktop is-12-tablet">
          <div class="box">
            <div class="is-flex chart-stats has-text-weight-bold">
              <span class="mr-1">{{ getRoundedNumber(currency.price) }}</span>
              <span class="mr-4">{{ $t('currency.BGN') }}</span>
              <span
                :class="
                  currency.change > 0 ? 'has-text-success' : 'has-text-danger'
                "
                >{{ getRoundedTwoDecimals(currency.change) }}%</span
              >
            </div>
            <img
              v-if="currency.chartDetailsURL"
              :src="currency.chartDetailsURL"
              decoding="async"
              loading="lazy"
              style="width: 100%"
            />
          </div>
          <div v-if="currency.shortDescription" class="box descr">
            <div :class="{ fade: !readMore }">
              <Markdown :text="currency.shortDescription" />
            </div>
            <b-collapse
              v-if="currency.description"
              :open="readMore"
              position="is-bottom"
              aria-id="readMoreContent"
              @open="readMore = !readMore"
              @close="readMore = !readMore"
            >
              <template #trigger="props">
                <a aria-controls="readMoreContent" :aria-expanded="props.open">
                  <b-icon
                    :icon="!props.open ? 'chevron-down' : 'chevron-up'"
                  ></b-icon>
                  {{
                    !props.open ? $t('labels.readMore') : $t('labels.readLess')
                  }}
                </a>
              </template>
              <Markdown :text="currency.description" />
            </b-collapse>
          </div>
        </div>
        <div class="column" ref="tab">
          <div class="box">
            <b-tabs
              position="is-centered"
              size="is-medium"
              class="block"
              :class="{ 'px-0': isMobile }"
              v-model="tab"
              @input="onTabChange"
            >
              <b-tab-item :label="$t('buttons.buy')" headerClass="buy"
                ><Coin :coin="currency" type="buy" ref="buy"
              /></b-tab-item>
              <b-tab-item :label="$t('buttons.sell')" headerClass="sell"
                ><Coin :coin="currency" type="sell" ref="sell"
              /></b-tab-item>
            </b-tabs>
          </div>
          <div v-if="isMobile && currency.shortDescription" class="box descr">
            <div :class="{ fade: !readMore }">
              <Markdown :text="currency.shortDescription" />
            </div>
            <b-collapse
              v-if="currency.description"
              :open="readMore"
              position="is-bottom"
              aria-id="readMoreContent"
              @open="readMore = !readMore"
              @close="readMore = !readMore"
            >
              <template #trigger="props">
                <a aria-controls="readMoreContent" :aria-expanded="props.open">
                  <b-icon
                    :icon="!props.open ? 'chevron-down' : 'chevron-up'"
                  ></b-icon>
                  {{
                    !props.open ? $t('labels.readMore') : $t('labels.readLess')
                  }}
                </a>
              </template>
              <Markdown :text="currency.description" />
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueBreakpointMixin from 'vue-breakpoint-mixin'
import numbersMixin from '@/mixins/numbers-mixin'
import { EUR_RATE } from '@/utils/constants'
import Coin from '@/components/trade/Coin.vue'
import Markdown from '@/components/common/Markdown.vue'
import CryptoLogo from '@/components/common/CryptoLogo'

export default {
  name: 'Trade',
  components: {
    Coin,
    Markdown,
    CryptoLogo,
  },
  mixins: [numbersMixin, VueBreakpointMixin],
  computed: {
    ...mapGetters({
      getCurrency: 'coin/currency',
    }),
    // currency() {
    //   if (!this.$route.params.id) return null
    //   return this.getCurrency(this.$route.params.id)
    // },
  },
  data() {
    return {
      tab: 0,
      currency: null,
      readMore: false,
    }
  },
  async created() {
    try {
      if (!this.$route.params.id) return

      const coinData = await this.fetchCurrency(this.$route.params.id)
      const pricesParams = {
        vs_currencies: 'eur',
        include_24hr_change: true,
        include_market_cap: true,
        ids: coinData.apiName,
      }
      const prices = await this.fetchPrices(pricesParams)
      const coinPrices = prices[coinData.apiName]
      this.currency = {
        ...coinData,
        ...coinPrices,
        price: coinPrices.eur ? coinPrices.eur * EUR_RATE : null,
        change: coinPrices.eur_24h_change || null,
      }

      this.tab = this.$route.name === 'sell' ? 1 : 0
    } catch (error) {
      this.$buefy.toast.open({
        duration: 3000,
        message: this.$t('errors.fetchError'),
        position: 'is-bottom',
        type: 'is-danger',
      })
    }
  },
  methods: {
    ...mapActions({
      fetchCurrency: 'coin/fetchCurrency',
      fetchPrices: 'coin/fetchPrices',
    }),
    onTabChange(ev) {
      const tabName = ev === 0 ? 'buy' : 'sell'
      this.$router.replace({
        name: tabName,
        params: { id: this.currency.id },
      })

      this.$refs[tabName].reset()
    },
    hasValidTags(tagsArr) {
      if (!tagsArr || !tagsArr.length) return null
      const tags = tagsArr.join(' ').trim()
      return tags
    },
  },
}
</script>

<style lang="scss" scoped>
.b-image-wrapper.image {
  display: inline-block;
  height: 100%;
  align-self: center;
  /deep/ .crypto-logo {
    vertical-align: middle;
  }
}
.tag.is-success.is-light {
  height: 1.8em !important;
  font-size: $size-8;
}
.b-tabs.block {
  /deep/ .buy.is-active a {
    color: inherit;
    border-bottom-color: $success;
    border-bottom-width: 2px;
    font-weight: bold;
  }
  /deep/ .sell.is-active a {
    color: inherit;
    border-bottom-color: $danger;
    border-bottom-width: 2px;
    font-weight: bold;
  }
}
.descr {
  /deep/ #readMoreContent {
    margin-bottom: 20px !important;
  }
  /deep/ .fade {
    position: relative;
  }
  /deep/ .fade:after {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    content: '';
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 5%,
      rgba(255, 255, 255, 0) 95%
    );
    pointer-events: none; /* so the text is still selectable */
  }
}
</style>
