<template>
  <div class="pt-4" :class="{ 'px-0': isMobile }">
    <div class="mobile-width centered">
      <!-- coin logo and name -->
      <div v-if="isMobile" class="crypto-order-logo mb-5">
        <crypto-logo
          :ticker="coin.ticker"
          :name="coin.name"
          :url="coin.logoURL"
        >
        </crypto-logo>
        <h1 class="title is-5">
          {{ type === 'sell' ? $t('pages.sell.title') : $t('pages.buy.title') }}
          {{ coin.name }} ({{ coin.ticker }})
        </h1>
      </div>
      <div class="content">
        <ValidationObserver v-slot="{ passes, valid }" tag="div" ref="observer">
          <form form @submit.prevent class="mb-5">
            <!-- amount -->
            <div class="pair">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|double"
                :name="$t('labels.amount')"
                tag="div"
                class="mb-4"
              >
                <b-field
                  :type="{ 'is-danger': errors[0] || validAmount }"
                  :message="errors[0] || validAmount || '-'"
                >
                  <b-input
                    v-model="amountGiven"
                    type="number"
                    min="0"
                    class="first"
                    style="width: 50%;"
                    :placeholder="coin.ticker"
                    @input="calculate($event, true)"
                  >
                  </b-input>
                  <b-input
                    v-model="amountReceived"
                    type="number"
                    min="0"
                    custom-class="has-text-right"
                    style="width: 50%;"
                    placeholder="BGN"
                    @input="calculate($event, false)"
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </div>
            <!-- sums and taxes -->
            <div class="box">
              <div class="columns">
                <span class="column is-7"
                  >{{ $t('labels.marketPrice') }} {{ coin.ticker }}:</span
                >
                <span class="column has-text-weight-semibold"
                  >{{ getRoundedNumber(marketPrice) || 0 }}
                  {{ $t('currency.BGN') }}</span
                >
              </div>
              <div v-if="type === 'buy' && blockchainTax" class="columns">
                <span class="column is-7"
                  >{{ $t('labels.blockchainTax') }}:</span
                >
                <span class="column has-text-weight-semibold"
                  >{{ getRoundedNumber(blockchainTax) }}
                  {{ $t('currency.BGN') }}</span
                >
              </div>
              <div class="columns is-vcentered">
                <span class="column is-7"
                  >{{ $t('labels.tax') }}
                  <span class="note"
                    >({{ $t('labels.minTax') }} {{ MIN_TAX }}
                    {{ $t('currency.BGN') }})</span
                  >:
                </span>
                <span class="column has-text-weight-semibold"
                  >{{ getRoundedNumber(tax) || 0 }}
                  {{ $t('currency.BGN') }}</span
                >
              </div>
              <div class="columns is-vcentered" v-if="displayEasyPayTax">
                <span class="column is-7"
                  >{{ $t('labels.easyPayTax') }}
                  <span class="note"
                    >(+ {{ getFloatWithTwoDecimals(easyPayTradeTax) }}%)</span
                  >:
                </span>
                <span class="column has-text-weight-semibold"
                  >{{ getRoundedNumber(easyPayTax) || 0 }}
                  {{ $t('currency.BGN') }}</span
                >
              </div>
              <div v-else>
                <br />
                <br v-show="isMobile" />
              </div>
              <div class="columns">
                <span class="column is-7">{{ totalSumLabel }}:</span>
                <span class="column has-text-weight-semibold"
                  >{{
                    getRoundedNumber(
                      type === 'buy' ? endTotalBGNToPay : endTotalBGNToReceive
                    ) || 0
                  }}
                  {{ $t('currency.BGN') }}</span
                >
              </div>
            </div>
            <!-- crypto address -->
            <div v-if="type === 'buy'">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                :name="$t('labels.cryptoAddress')"
                tag="div"
                class="mb-4"
              >
                <b-field
                  :type="{ 'is-danger': errors[0] }"
                  :message="errors[0] || '-'"
                >
                  <template #label>
                    {{ $t('labels.cryptoAddress') }}
                    <span
                      class="is-size-9 ml-2 has-text-info"
                      style="cursor: pointer;"
                      @click="isWalletActive = true"
                      >({{ $t('labels.dontHaveAddress') }})</span
                    >
                  </template>
                  <b-input
                    v-model="addressGiven"
                    type="text"
                    :placeholder="$t('labels.cryptoAddress')"
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </div>
            <!-- payment method -->
            <div class="payment-method">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                :name="paymentMethodLabel"
                tag="div"
                class="mb-4"
              >
                <b-field
                  :label="paymentMethodLabel"
                  :type="{ 'is-danger': errors[0] }"
                  :message="errors[0] || '-'"
                  custom-class="payment-method-btns"
                >
                  <b-radio-button
                    v-for="(option, index) in paymentMethodOptions"
                    :key="index"
                    v-model="paymentMethod"
                    :native-value="option.value"
                    type="is-dark"
                    expanded
                    @input="onPaymentMethodChange"
                  >
                    <b-icon :icon="option.icon"></b-icon>
                    <span class="ml-2">{{ option.name }}</span>
                  </b-radio-button>
                </b-field>
              </ValidationProvider>
            </div>
            <!-- account id -->
            <div v-if="receiverAccountIdLabel && type === 'sell'">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                :name="receiverAccountIdLabel"
                tag="div"
                class="mb-4"
              >
                <b-field
                  class="mb-0"
                  :label="receiverAccountIdLabel"
                  :type="{ 'is-danger': errors[0] }"
                  :message="errors[0] || '-'"
                >
                  <b-input v-model="receiverAccountId"> </b-input>
                </b-field>
              </ValidationProvider>
            </div>
            <!-- submit btn -->
            <b-button
              type="is-dark"
              size="is-medium"
              icon-right="arrow-right"
              expanded
              :disabled="!valid || isWarningActive || validAmount"
              @click.prevent="passes(handleContinue)"
              >{{ $t('buttons.continue') }}</b-button
            >
            <!-- easypay warning -->
            <b-notification
              has-icon
              :duration="10000"
              type="is-warning"
              position="is-bottom"
              v-model="isWarningActive"
              aria-close-label="Close notification"
              role="alert"
              class="mt-4"
            >
              {{ $t('pages.sell.easyPayWarning') }}
            </b-notification>
            <!-- wallet installation -->
            <b-modal
              v-model="isWalletActive"
              :width="640"
              :can-cancel="['escape', 'outside']"
              scroll="keep"
            >
              <template #default="props">
                <Wallet @close="props.close" />
              </template>
            </b-modal>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueBreakpointMixin from 'vue-breakpoint-mixin'
import numbersMixin from '@/mixins/numbers-mixin'
import CryptoLogo from '@/components/common/CryptoLogo'
import Wallet from '@/components/trade/Wallet'
import {
  EUR_RATE,
  TAX,
  MIN_TAX,
  EASYPAY_SELL_TAX,
  EASYPAY_BUY_TAX,
  MIN_AMOUNT,
  EASYPAY_MAX,
} from '@/utils/constants'

export default {
  name: 'Coin',
  props: {
    type: {
      type: String,
      default: '',
    },
    coin: {
      type: Object,
      required: true,
    },
  },
  components: { CryptoLogo, Wallet },
  mixins: [VueBreakpointMixin, numbersMixin],
  data() {
    return {
      amountGiven: null,
      amountReceived: null,
      MIN_TAX,
      tax: MIN_TAX,
      easyPayTax: 0,
      displayEasyPayTax: false,
      endTotalBGNToReceive: 0,
      endTotalBGNToPay: 0,
      addressGiven: null,
      paymentMethodOptions: [
        {
          name: this.$t('pages.buy.bankTransfer'),
          value: {
            id: 'bankTransfer',
            v: this.$t('pages.buy.bankTransfer'),
            inputLabel: this.$t('pages.buy.iban'),
            details: '',
          },
          icon: 'fa-solid fa-building-columns fa-xl',
        },
        {
          name: this.$t('pages.buy.easyPayLocation'),
          value: {
            id: 'easyPayLocation',
            v: this.$t('pages.buy.easyPayLocation'),
            inputLabel: '',
            details: '',
          },
          icon: 'fa-solid fa-money-bill fa-xl',
        },
      ],
      paymentMethod: null,
      receiverAccountId: null,
      receiverAccountIdLabel: '',
      isDirty: false,
      isWarningActive: false,
      isWalletActive: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
    }),
    validAmount() {
      if (!this.isDirty || +this.amountReceived >= MIN_AMOUNT) {
        return null
      }
      return this.$t('validation.minAmount', { val: MIN_AMOUNT })
    },
    marketPrice() {
      if (!this.coin.eur) return null
      return (this.coin.eur * EUR_RATE * 100) / 100
    },
    blockchainTax() {
      if (!this.marketPrice || !this.coin.blockchainTax) return 0
      return this.coin.blockchainTax * this.marketPrice
    },
    easyPayTradeTax() {
      return this.type === 'buy' ? EASYPAY_BUY_TAX : EASYPAY_SELL_TAX
    },
    paymentMethodLabel() {
      return this.type === 'buy'
        ? this.$t('pages.buy.paymentMethod')
        : this.$t('pages.sell.paymentMethod')
    },
    totalSumLabel() {
      return this.type === 'buy'
        ? this.$t('labels.endTotalBGNToPay')
        : this.$t('labels.endTotalBGNToReceive')
    },
  },
  methods: {
    ...mapActions({
      getProfile: 'user/getProfile',
      setOrderDetails: 'orders/setOrderDetails',
      logout: 'auth/logout',
    }),
    async calculate(val, fromGiven) {
      this.isDirty = true

      if (!val) {
        this.resetCalculations()
        return
      }

      if (fromGiven) {
        this.amountReceived = (this.marketPrice * val).toFixed(2)
      } else {
        this.amountGiven = (val / this.marketPrice).toFixed(4)
      }

      this.calculateTotal()
    },
    calculateTotal() {
      const actualTax = (+this.amountReceived * TAX) / 100
      this.tax = MIN_TAX > actualTax ? MIN_TAX : actualTax

      const actualEasyPayTax =
        (+this.amountReceived * this.easyPayTradeTax) / 100
      this.easyPayTax = this.displayEasyPayTax ? actualEasyPayTax : 0

      if (this.type === 'sell') {
        this.endTotalBGNToReceive = (
          +this.amountReceived -
          this.tax -
          this.easyPayTax
        ).toFixed(2)
        this.endTotalBGNToPay = 0
        this.isWarningActive =
          this.endTotalBGNToReceive > EASYPAY_MAX && this.displayEasyPayTax
      } else {
        this.endTotalBGNToPay = (
          +this.amountReceived +
          this.tax +
          this.blockchainTax +
          this.easyPayTax
        ).toFixed(2)
        this.endTotalBGNToReceive = 0
        this.isWarningActive =
          this.endTotalBGNToPay > EASYPAY_MAX && this.displayEasyPayTax
      }
    },
    reset() {
      this.resetCalculations()
      this.addressGiven = null
      this.paymentMethod = null
      this.displayEasyPayTax = false
      this.receiverAccountId = null
      this.receiverAccountIdLabel = ''
    },
    resetCalculations() {
      this.amountGiven = null
      this.amountReceived = null
      this.endTotalBGNToPay = 0
      this.endTotalBGNToReceive = 0
      this.tax = MIN_TAX
      this.easyPayTax = 0
      this.isWarningActive = false
      this.isWalletActive = false
      this.isDirty = false
      this.$refs.observer.reset()
    },
    onPaymentMethodChange(ev) {
      switch (ev.id) {
        case 'bankTransfer': {
          this.receiverAccountIdLabel = ev.inputLabel
          this.receiverAccountId = null
          this.displayEasyPayTax = false
          break
        }
        case 'easyPayLocation': {
          this.receiverAccountIdLabel = ''
          this.receiverAccountId = this.$t('pages.sell.easyPayLocation')
          this.displayEasyPayTax = true
          break
        }
        default:
          break
      }

      if (!this.amountReceived) {
        return
      }

      this.calculateTotal()
    },
    async handleContinue() {
      try {
        await this.getProfile()
      } catch (error) {
        this.logout()
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t('errors.unauthenticate'),
          position: 'is-bottom',
          type: 'is-danger',
        })
      }

      try {
        await this.setOrderDetails({
          type: this.type,
          currencyGiven: this.coin,
          marketPrice: this.marketPrice,
          tax: this.tax,
          easyPayTax: this.easyPayTax,
          blockchainTax: this.type === 'buy' ? this.blockchainTax : 0, // only for buy
          paymentMethod: this.paymentMethod,
          addressGiven: this.type === 'buy' ? this.addressGiven : null, // only for buy
          receiverAccountId:
            this.type === 'sell' ? this.receiverAccountId : null, // only for sell
          amountGiven: this.amountGiven,
          amountReceived:
            this.type === 'buy'
              ? this.endTotalBGNToPay
              : this.endTotalBGNToReceive,
          email: this.user.email,
        })
        this.$router.push({ name: 'order' })
      } catch (error) {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t('errors.unauthenticate'),
          position: 'is-bottom',
          type: 'is-danger',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.pair {
  .control {
    &.first {
      &:before {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' width='1em' height='1em' focusable='false' role='img' aria-label='arrow left right' xmlns='http://www.w3.org/2000/svg' fill='white' class='bi-arrow-left-right mx-auto b-icon bi' data-v-41be6633=''%3E%3Cg data-v-41be6633=''%3E%3Cpath fill-rule='evenodd' d='M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-color: $blue-dark;
        border-radius: 28px;
        padding: 10px 16px;
        position: absolute;
        left: 83%;
        top: -8px;
        z-index: 5;
        height: 57px;
        width: 57px;
        background-position: center;
      }
    }
  }
}
.note {
  font-size: $size-9;
}

.payment-method {
  margin: 10px 0 20px;

  /deep/ select {
    background-color: inherit;
    box-shadow: 0px 5px 15px 0px #eee;
    height: 2.9em;
    line-height: 1.5;
    //padding: 1.25rem 0.75rem;
    border-color: transparent;
    border-width: 2px;
    color: $dark;
  }

  /deep/ .button {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    background-color: inherit;
    border: 1px solid gray;
    padding-left: 0.9em;
    padding-right: 0.9em;
  }

  /deep/ .button.is-selected {
    box-shadow: none;
    background-color: $dark;
  }
}

.columns {
  margin: 0;
}

.column {
  padding: 0;
}
</style>
