<template>
  <div class="card">
    <header class="modal-card-head has-background-light">
      <span class="modal-card-title has-text-dark">
        {{ $t('pages.address.title') }}
      </span>
      <button type="button" class="delete" @click="$emit('close')" />
    </header>
    <div class="section p-3">
      <div class="mobile-width centered p-5">
        <!-- <h1 class="title is-5">{{ $t('pages.address.title') }}</h1> -->
        <div class="content">
          <div class="columns is-mobile is-vcentered">
            <div class="column is-12">
              <div class="content pl-4">
                <p class="is-flex is-align-items-center wallet">
                  <b-image
                    style="width: 30px; min-width: 30px"
                    responsive
                    :src="require(`@/assets/images/coinbase-wallet.png`)"
                    alt="coinbase wallet"
                    class="mr-4"
                    custom-class="crypto-logo"
                  />
                  <a
                    href="https://www.coinbase.com/wallet"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Coinbase Wallet</a
                  >
                </p>
                <p
                  v-html="$t('pages.address.message')"
                  class="has-text-justified"
                  style="white-space: pre-line;"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Wallet',
}
</script>

<style lang="scss" scoped>
.wallet {
  /deep/ .b-image-wrapper {
    margin: 0;
  }
  /deep/ .crypto-logo {
    vertical-align: middle;
  }
}
</style>
